// General
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  isUnity,
  LocalizationNS,
  sessionStorageIdentifiers,
  useFeatureFlag,
  useGetOrganization,
  useGetToken,
  useI18n,
  withI18n,
} from "compass-commons";
import { getOrgIdFromToken } from "compass-shared-services";
// Styles
import "./App.module.css";
import "./assets/css/global.css";
import "./styles.scss";
// Components
import { AuthWrapper, Alert, Button } from "dms-lib";
import { Portal, CircularProgress } from "@mui/material";
import ConfigLayout from "./components/configLayout/ConfigLayout";
import ConfigLayoutOperatorGuide from "./components/configLayout/ConfigLayoutOperatorGuide";
// Services
import StateService from "./services/StateService";
import UserManagerService from "./services/UserManagerService";
// Contexts
import { StateProvider } from "./contexts/StateContext";
// Router
import { ROOTPATH } from "./router/Routes";
// Store
import { StoreProvider } from "./store";
// Utils
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";

/**
 * Remove when the move to Unity is complete
 */
const Router = isUnity || !isDMS ? HashRouter : BrowserRouter;

const stateService: StateService = new StateService();

interface AppProps {
  onlyOg: boolean;
}

const App = ({ onlyOg }: AppProps): JSX.Element => {
  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();

  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();

  const { t: translate } = useI18n();
  const orgId = getOrgIdFromToken(token);
  const organizationId = useGetOrganization();

  const isSwitchingOrg = organizationId && organizationId !== orgId;

  const isLoggedInUser = Boolean(token);

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );

  const { alertSubject } = stateService;

  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  const setMainHelpLinkKeyword = () => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (
      !mainContextHelpKeyword ||
      mainContextHelpKeyword !== appConfig.MFE_HELP_LINK_KEYWORD
    ) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        appConfig.MFE_HELP_LINK_KEYWORD
      );
    }
  };

  useEffect(() => {
    setMainHelpLinkKeyword();
  }, []);

  useEffect(() => {
    if (!isLoggedInUser) return;
    UserManagerService.isUserAllowed().then((value) => {
      setIsAllowedUser(value);
    });
  }, [isLoggedInUser]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isStandalone) return;
    navigate(ROOTPATH);
  }, [orgId]);

  return (
    (isLoggedInUser || !!isStandalone) && (
      <React.StrictMode>
        <StoreProvider>
          <StateProvider value={stateService}>
            <AuthWrapper
              isAuthorized={isAllowedUser || !!isStandalone}
              isLoading={isStandalone ? false : isAllowedUser === undefined}
              unauthorizedTitle={translate("unauthorized", {
                ns: LocalizationNS.SHARED,
              })}
              unauthorizedDescription={translate("unauthorizedContact", {
                ns: LocalizationNS.SHARED,
              })}
            >
              <div className="configuration-root" data-cr="configuration-root">
                {isSwitchingOrg ? (
                  <div className="configuration-loading-container">
                    <CircularProgress />
                  </div>
                ) : (
                  <Routes>
                    {onlyOg ? (
                      <>
                        <Route
                          path={`${ROOTPATH}/`}
                          element={<ConfigLayoutOperatorGuide key={orgId} />}
                        />
                        <Route
                          path={`${ROOTPATH}/:siteId/*`}
                          element={<ConfigLayoutOperatorGuide key={orgId} />}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          path={`${ROOTPATH}/`}
                          element={<ConfigLayout key={orgId} />}
                        />
                        <Route
                          path={`${ROOTPATH}/:siteId/*`}
                          element={<ConfigLayout key={orgId} />}
                        />
                      </>
                    )}
                  </Routes>
                )}
              </div>
              {isDMS ? (
                <Portal>
                  <Alert alertNotificationSubject={alertSubject} />
                </Portal>
              ) : (
                <Alert
                  alertNotificationSubject={alertSubject}
                  autoHideDuration={4000}
                />
              )}
            </AuthWrapper>
          </StateProvider>
        </StoreProvider>
      </React.StrictMode>
    )
  );
};

const AppWithRouter = ({ onlyOg = false }: AppProps) => (
  <Router>
    <App onlyOg={onlyOg} />
  </Router>
);
export default withI18n(AppWithRouter);
