/**
 * ROUTER CONFIGURATION HELPERS
 */
import { isUnity } from "compass-commons";
import { MenuTabRoute } from "../models/configMenu/MenuTabRoute";

const COMPASS_ROOTPATH =
  appConfig.COMPASS_CONFIGURATION_PATH || "/ui/dev/live/configuration";

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const url = !isStandalone ? window.location.href : "/configure/configuration";
const middlePath = getMiddlePath(url, "configure", "configuration");

/* Unity and SOC use hash routes */
export const ROOTPATH =
  isUnity || !isDMS ? `/configure${middlePath}configuration` : COMPASS_ROOTPATH;

/**
 * Helper to build relative paths for our configuration menu
 */
export const RoutesMap = [
  { path: MenuTabRoute.Generic, otherPaths: [], name: "navmenu.general" },
  { path: MenuTabRoute.Subsystems, otherPaths: [], name: "navmenu.subsystems" },
  { path: MenuTabRoute.Resources, otherPaths: [], name: "navmenu.resources" },
  { path: MenuTabRoute.FloorPlans, otherPaths: [], name: "navmenu.floorPlans" },
  {
    path: MenuTabRoute.OpGuides,
    otherPaths: [],
    name: "navmenu.operatorGuide",
  },
  { path: MenuTabRoute.Incidents, otherPaths: [], name: "navmenu.incidents" },
];

export const MonitorRoutesMap = RoutesMap.filter(
  (route) => route.path !== MenuTabRoute.OpGuides
);
