import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  Connection,
  Controls,
  Edge,
  Node,
  NodeDimensionChange,
  ReactFlowInstance,
  useNodesInitialized,
} from "reactflow";
import "./ogEditPanel.module.css";
import { useI18n } from "compass-commons";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { OgTaskTypeDto } from "../../../models/ogTaskTypes/OgTaskTypeDto";
import { OgTemplateDto } from "../../../models/ogTemplate/OgTemplateDto";
import GenericTaskNode, {
  GenericTaskNodeProps,
} from "./components/nodes/GenericTaskNode";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";
import { fillTaskTypeIds } from "../../../helpers/ogConfiguratorHelper";
import InitialNode from "./components/nodes/InitialNode";
import RemovableEdge, {
  RemovableEdgeProps,
} from "./components/edges/RemovableEdge";
import { OgBlockTypeLinkDto } from "../../../models/ogTaskTypes/OgBlockTypeLinkDto";
import OgEditPanelNotSelected from "./components/OgEditPanelNotSelected";
import { useNodes } from "./hooks/useNodes";
import { useEdges } from "./hooks/useEdges";
import { findAssociatedEdges, INITIAL_SOURCE } from "./helpers/edges";
import {
  connectionExists,
  insertConnectionTaskType,
  matchSourceHandles,
} from "./helpers/connections";
import { selectOperatorGuide, useStoreDispatch } from "../../../store";
import { operatorGuideActions } from "../../../store/operatorGuide";
import { editionMode } from "../../../store/root";

interface OgEditPanelProps {
  selectedOgTemplate?: OgTemplateDto;
  loadTemplates?: boolean;
}

const RREACT_FLOW = "application/reactflow";
const DRAG_PREVIEW_RATIO = 1.2;
const CONNECTION_RADIUS = 40;
const OgEditPanel = (props: OgEditPanelProps): JSX.Element => {
  const { selectedOgTemplate, loadTemplates = false } = props;
  const stateService: StateService = useStateContext();
  const nodesInitialized = useNodesInitialized();
  const { currentOgTemplate } = stateService;
  const isEditionMode = useSelector(editionMode);
  const dispatch = useStoreDispatch();
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [, setDragging] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const { editingTaskText } = useSelector(selectOperatorGuide);
  const { t } = useI18n();

  useEffect(() => {
    if (selectedOgTemplate) {
      currentOgTemplate.next(selectedOgTemplate);
      setIsModified(true);
    }
  }, [selectedOgTemplate]);

  function addTaskTypeToOgTemplate(type: OgTaskTypeDto) {
    if (selectedOgTemplate) {
      if (!selectedOgTemplate.taskTypeDTOList) {
        selectedOgTemplate.taskTypeDTOList = [type];
      } else {
        selectedOgTemplate.taskTypeDTOList.push(type);
      }
      selectedOgTemplate.modified = true;
      setIsModified(true);
    }
  }

  function updateTaskTypePosition(node: Node) {
    if (node) {
      const id = node?.data?.id;
      const position = node?.position;
      selectedOgTemplate?.taskTypeDTOList?.forEach((task) => {
        if (task.id === id) {
          const threshold = 10;
          const currentTaskPosition = JSON.parse(task.position);
          if (
            !(
              Math.abs(currentTaskPosition.x - position.x) > threshold ||
              Math.abs(currentTaskPosition.y - position.y) > threshold
            )
          )
            return;
          task.position = JSON.stringify(position);
          selectedOgTemplate.modified = true;

          setIsModified(true);
        }
      });
    }
  }

  function addConnectionToTaskType(params: Edge) {
    selectedOgTemplate?.taskTypeDTOList?.forEach((task) => {
      if (params?.source === task.id) {
        insertConnectionTaskType(task, params);
        selectedOgTemplate.modified = true;
        setIsModified(true);
      }
    });
  }

  function removeTaskTypeFromOgTemplate(taskId: string) {
    for (let i = 0; i < selectedOgTemplate?.taskTypeDTOList?.length; i++) {
      if (selectedOgTemplate.taskTypeDTOList.at(i).id === taskId) {
        selectedOgTemplate.taskTypeDTOList.splice(i, 1);
        break;
      }
    }
    if (selectedOgTemplate.taskTypeDTOList?.length === 0) {
      selectedOgTemplate.entryPointId = null;
    }
    selectedOgTemplate.modified = true;
    setIsModified(true);
  }

  function removeConnectionBlockLink(
    blockLink: OgBlockTypeLinkDto,
    connection: Edge
  ) {
    if (
      matchSourceHandles(blockLink?.id, connection.sourceHandle) &&
      blockLink?.linkNextTask === connection.target
    ) {
      blockLink.linkNextTask = null;
    }
  }

  function removeConnectionFromOgTemplate(connection: Edge) {
    for (let i = 0; i < selectedOgTemplate?.taskTypeDTOList?.length; i++) {
      const task: OgTaskTypeDto = selectedOgTemplate.taskTypeDTOList.at(i);
      if (task.id === connection.source || task.id === connection.target) {
        task.typeBlocks?.forEach((block) => {
          removeConnectionBlockLink(block.button, connection);
          removeConnectionBlockLink(block.positiveAnswer, connection);
          removeConnectionBlockLink(block.negativeAnswer, connection);
          block.options?.forEach((option) => {
            removeConnectionBlockLink(option, connection);
          });
          removeConnectionBlockLink(block.found, connection);
          removeConnectionBlockLink(block.notFound, connection);
        });
      }
    }
    selectedOgTemplate.modified = true;
    setIsModified(true);
  }

  function removeOptionOgTemplate(blockTypeId: string, optionId: string) {
    if (selectedOgTemplate) {
      selectedOgTemplate.taskTypeDTOList?.forEach((task) => {
        task.typeBlocks?.forEach((block) => {
          if (block.id === blockTypeId) {
            const newOptions: OgBlockTypeLinkDto[] = [];
            block.options?.forEach((opt) => {
              if (opt.id !== optionId) {
                newOptions.push(opt);
              }
            });
            block.options = newOptions;
          }
        });
      });
      selectedOgTemplate.modified = true;
      setIsModified(true);
    }
  }

  // INIT EDGES AND NODES HOOKS

  const [edges, onEdgesChange, onAddEdge, onEdgesDelete] =
    useEdges(selectedOgTemplate);

  const onEdgeRemoved = (removedEdge: Edge) => {
    removeConnectionFromOgTemplate(removedEdge);
  };

  const onEdgesRemove = (dataIds: string[]) => {
    onEdgesDelete(dataIds, onEdgeRemoved);
  };

  const [nodes, onNodesChange, onNodeDelete, onAddNode] = useNodes(
    reactFlowInstance,
    reactFlowWrapper,
    selectedOgTemplate
  );

  useEffect(() => {
    if (nodesInitialized) {
      if (!isEditionMode) setHasFocus(false);
      else if (!hasFocus) setHasFocus(true);
      else return;
      setTimeout(() => {
        if (
          selectedOgTemplate &&
          selectedOgTemplate?.taskTypeDTOList.length > 0
        ) {
          reactFlowInstance.fitView({
            maxZoom: 1.2,
            minZoom: 0,
            duration: 250,
          });
        }
      }, 100);
      reactFlowInstance.setViewport({ x: -50, y: 0, zoom: 1 });
    }
  }, [nodesInitialized, selectedOgTemplate, isEditionMode]);

  const onNodeRemoved = useCallback(
    ({ id }: Node) => {
      onEdgesRemove(findAssociatedEdges(id, edges));
      removeTaskTypeFromOgTemplate(id);
    },
    [edges]
  );

  const onNodeRemove = (dataId: string) => {
    onNodeDelete(dataId, onNodeRemoved);
  };

  const onMultiOptionRemoved = useCallback(
    (blockId: string, optionId: string) => {
      onEdgesRemove(findAssociatedEdges(optionId, edges));
      removeOptionOgTemplate(blockId, optionId);
    },
    [edges]
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!isEditionMode || connectionExists(connection, edges)) return;
      const newEdge = onAddEdge(connection);
      addConnectionToTaskType(newEdge);

      if (connection.source === INITIAL_SOURCE) {
        selectedOgTemplate.entryPointId = connection.target;
        currentOgTemplate.next(selectedOgTemplate);
      }
    },
    [edges, isEditionMode]
  );

  const onDrop = (event) => {
    setDragging(false);
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type: OgTaskTypeDto = JSON.parse(
      event.dataTransfer.getData(RREACT_FLOW)
    ) as OgTaskTypeDto;
    const pos = JSON.parse(
      event.dataTransfer.getData(`${RREACT_FLOW}/position`)
    ) as Array<number>;

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - DRAG_PREVIEW_RATIO * pos[0],
      y: event.clientY - reactFlowBounds.top - DRAG_PREVIEW_RATIO * pos[1],
    });
    type.position = JSON.stringify(position);
    onAddNode(type, fillTaskTypeIds(type), position);
    type.typeBlocks.forEach((block) => {
      dispatch(operatorGuideActions.addTemplateStub(block.id));
      block.options?.forEach((option) => {
        dispatch(operatorGuideActions.addTemplateStub(option.id));
      });
    });
    addTaskTypeToOgTemplate(type);
  };

  const onNodeDrag = (event, node: Node) => {
    updateTaskTypePosition(node);
  };

  const onInit = (rf: ReactFlowInstance) => {
    setTimeout(() => {
      setReactFlowInstance(rf);
    }, 0);

    return () => {
      if (reactFlowInstance) {
        setReactFlowInstance(null);
      }
    };
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    setDragging(true);
  };

  const onDragLeave = () => {
    setDragging(false);
  };

  const nodeTypes = useMemo(
    () => ({
      taskType: (genericTaskProps: GenericTaskNodeProps) => (
        <GenericTaskNode
          {...genericTaskProps}
          onNodeRemove={onNodeRemove}
          onMultiOptionRemove={onMultiOptionRemoved}
        />
      ),
      initial: InitialNode,
    }),
    [onNodeRemoved, onMultiOptionRemoved]
  );

  const edgeTypes = useMemo(
    () => ({
      removable: (removableProps: RemovableEdgeProps) => (
        <RemovableEdge {...removableProps} onEdgesRemove={onEdgesRemove} />
      ),
    }),
    [edges]
  );

  useEffect(() => {
    if (isModified) {
      if (nodes.length > 0 && nodes[0].id === "initial") {
        nodes[0].className = "editing";
        onNodesChange([
          { id: nodes[0].id, updateStyle: true } as NodeDimensionChange,
        ]);
      }
    }
  }, [isModified]);

  const isLoadingComponent = () => {
    return (
      <div className="config-loading">
        <CircularProgress color="inherit" />
      </div>
    );
  };
  return (
    <div className="og-edit-panel-main" data-cr="og-edit-panel">
      {loadTemplates && isLoadingComponent()}
      {!loadTemplates && selectedOgTemplate && (
        <div className="dndflow" data-cr="og-edit-react-flow">
          <div
            className="react-flow-wrapper"
            data-cr="og-edit-react-flow-wrapper"
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              defaultViewport={{ x: -50, y: 0, zoom: 1 }}
              onConnect={onConnect}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onInit={onInit}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onNodeDragStop={onNodeDrag}
              proOptions={{ hideAttribution: true }}
              connectionRadius={CONNECTION_RADIUS}
              nodesDraggable={isEditionMode && !editingTaskText}
              nodesConnectable={isEditionMode}
              edgesFocusable={isEditionMode}
              edgesUpdatable={isEditionMode}
              elementsSelectable={isEditionMode}
              panOnDrag={!editingTaskText}
            >
              {selectedOgTemplate.taskTypeDTOList?.length === 0 &&
              isEditionMode ? (
                <div className="og-edit-panel-empty-task-list">
                  {t("operatorGuide.dragTask")}
                </div>
              ) : (
                <Controls showInteractive={false} />
              )}
            </ReactFlow>
          </div>
        </div>
      )}
      {!loadTemplates && !selectedOgTemplate && <OgEditPanelNotSelected />}
    </div>
  );
};

export default OgEditPanel;
