// General
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Styles
import "./generalConfiguration.module.css";
// Components
import { useFeatureFlag } from "compass-commons";
import GeneralInformation from "./components/generalInformation/GeneralInformation";
import CompassDialog from "../commons/dialog";
import SiteDialogContent from "./components/siteDialogContent";
import SiteDialogActions from "./components/siteDialogActions";
// Hooks
import useSiteActions from "../../hooks/useSiteActions";
// Contexts
import { useTabActionsContext } from "../../contexts/TabActionsContext";
// Store
import { deletionMode, readOnlyMode } from "../../store/root";
import { selectSiteCRUDLoading, siteBeingManipulated } from "../../store/sites";
import { getTimeZoneOptions } from "../../utils/Util";
import { NOGGIN_CONFIG } from "../../utils/Constants";

/**
 * Wrapper Layout for the General Configurator panel
 * This panel is related to sites configuration
 * @returns JSX.Element
 */
const GeneralConfigurator = (): JSX.Element => {
  const isDeletionMode = useSelector(deletionMode);
  const isSiteCRUDloading = useSelector(selectSiteCRUDLoading);
  const isSiteBeingManipulated = useSelector(siteBeingManipulated);
  const isReadOnly = useSelector(readOnlyMode);

  const {
    deleteSite,
    cancelAction,
    updateSite,
    createSite,
    updateConfiguration,
  } = useSiteActions();

  // Actions Context
  const { setActivateAll, setDisableAllButtons } = useTabActionsContext();

  // Local State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { enabled: isNogginEnabled } = useFeatureFlag(appConfig, NOGGIN_CONFIG);

  //
  // MODAL ACTIONS
  //

  /**
   * Action responsable to update the modal status
   */
  // eslint-disable-next-line
  const toggleModal = useCallback((event?: any, forceState?: boolean) => {
    if (event) event.stopPropagation();
    setOpenDeleteModal((prevState: boolean) => forceState ?? !prevState);
  }, []);

  //
  // CONFIG BREADCRUMB ACTIONS CALLBACKS
  //

  /**
   * Action responsable to delete a site from the tree
   */
  const deleteSiteSuccess = () => deleteSite(() => toggleModal(null, false));
  const deleteSiteClean = (event) =>
    cancelAction(() => toggleModal(event, false));

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setActivateAll(true);
  };

  //
  // LifeCycles
  //
  useEffect(() => {
    initTabActionsConfig();
  }, []);

  useEffect(() => {
    setDisableAllButtons(
      isSiteCRUDloading || (!isReadOnly && !isSiteBeingManipulated)
    );
  }, [isSiteBeingManipulated, isReadOnly, isSiteCRUDloading]);

  // Fetch Sites
  useEffect(() => {
    if (isDeletionMode) toggleModal(null, true);
  }, [isDeletionMode]);

  const timezones = React.useMemo(() => {
    return getTimeZoneOptions(true);
  }, []);

  return (
    <>
      <div data-cr="config-general-panel" className="config-general">
        <article className="config-general__panel compass-rounded-corner config-general__general">
          <GeneralInformation
            timezones={timezones}
            updateSite={updateSite}
            createSite={createSite}
            cancelAction={cancelAction}
            updateConfiguration={updateConfiguration}
            isNogginEnabled={isNogginEnabled}
          />
        </article>
        <article className="config-general__panel compass-rounded-corner" />
        <article className="config-general__panel compass-rounded-corner" />
      </div>
      <CompassDialog
        onClose={deleteSiteClean}
        dialogState={openDeleteModal}
        dialogContent={<SiteDialogContent />}
        dialogActions={
          <SiteDialogActions
            onCancel={deleteSiteClean}
            onSuccess={deleteSiteSuccess}
          />
        }
      />
    </>
  );
};

export default GeneralConfigurator;
