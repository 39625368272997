// General
import React, { useCallback, useEffect } from "react";
import { useI18n, incidentPriority } from "compass-commons";
import { Control, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
// Styles
import "./behaviourProperties.module.css";
// Store
import { selectIncidents, useStoreDispatch } from "../../../../store";
import { incidentsActions } from "../../../../store/incidents";
// Components
import BehaviourPropertiesTitle from "./components/BehaviourPropertiesTitle";
import BehaviourPropertiesPriority from "./components/BehaviourPropertiesPriority";
import BehaviourPropertiesIncidentType from "./components/BehaviourPropertiesIncidentType";
import BehaviourPropertiesOGTemplate from "./components/BehaviourPropertiesOGTemplate";
// Models
import { FormErrors } from "../../models/BehaviourFormDataTypes";
import { FormRuleDto } from "../../../../models/incidents/form/FormRuleDto";
import { IncidentTypeDto } from "../../../../models/incidents/IncidentTypeDto";
import { OgTemplateDto } from "../../../../models/ogTemplate/OgTemplateDto";
import BehaviourPropertiesAggregationMethod from "./components/BehaviourPropertiesAggregationMethod";
import BehaviourPropertiesAutomatic from "./components/BehaviourPropertiesAutomatic";

interface BehaviourPropertyPanelProps {
  ruleId: string;
  selectedSiteId: string;
  readOnlyMode: boolean;
  showAggregationMethod: boolean;
  control: Control<FormRuleDto, any>;
  reset: () => void;
  errors?: FormErrors<FormRuleDto>;
  setFieldValue: UseFormSetValue<FormRuleDto>;
  resetField: (name: string, options?: any) => void;
}

const BehaviourPropertyPanel = (
  props: BehaviourPropertyPanelProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    ruleId,
    readOnlyMode,
    control,
    reset,
    errors,
    setFieldValue,
    selectedSiteId,
    showAggregationMethod,
    resetField,
  } = props;

  const priorities = [
    incidentPriority.CRITICAL,
    incidentPriority.MAJOR,
    incidentPriority.MINOR,
    incidentPriority.WARNING,
  ];

  const dispatch = useStoreDispatch();
  const {
    incidentTypes,
    loadingIncidentTypes,
    operatorGuideTemplates,
    loadingOperatorGuideTemplates,
  } = useSelector(selectIncidents);

  const loadIncidentTypes = () => {
    dispatch(incidentsActions.getIncidentTypes(false));
  };

  const loadOperatorGuides = () => {
    dispatch(incidentsActions.getOperatorGuideTemplates(selectedSiteId));
  };

  const handleSelectIncidentType = useCallback(
    (incidentType: IncidentTypeDto) => {
      setFieldValue("behaviour.incidentTypeId", incidentType?.id || "");
      setFieldValue("behaviour.incidentTypeName", incidentType?.name || "");
      setFieldValue(
        "behaviour.incidentTypeIconPath",
        incidentType?.iconPath || ""
      );
    },
    []
  );

  const handleSelectOperatorGuide = (ogTemplate: OgTemplateDto) => {
    setFieldValue("behaviour.ogTemplateId", ogTemplate?.id || null);
    setFieldValue("behaviour.ogTemplateName", ogTemplate?.name || null);
  };

  const handleVideoSources = (videoSources: number) => {
    setFieldValue("behaviour.videoSources", videoSources);
  };

  useEffect(() => {
    reset();
    if (!readOnlyMode) {
      loadIncidentTypes();
      loadOperatorGuides();
    }
  }, [ruleId, readOnlyMode]);

  return (
    <>
      {ruleId ? (
        <div data-cr="config-incidents__properties-panel">
          <b>3. {translate("behaviors.properties")}</b>
          <div className="config-incidents__properties__panel">
            <BehaviourPropertiesTitle
              ruleId={ruleId}
              readOnlyMode={readOnlyMode}
              control={control}
              errors={errors}
              setFieldValue={setFieldValue}
            />

            {showAggregationMethod && (
              <BehaviourPropertiesAggregationMethod
                ruleId={ruleId}
                readOnlyMode={readOnlyMode}
                control={control}
              />
            )}
            <BehaviourPropertiesPriority
              readOnlyMode={readOnlyMode}
              control={control}
              priorities={priorities}
            />
            <BehaviourPropertiesIncidentType
              ruleId={ruleId}
              readOnlyMode={readOnlyMode}
              control={control}
              incidentTypes={incidentTypes}
              handleSelectIncidentType={handleSelectIncidentType}
              loading={loadingIncidentTypes}
              errors={errors}
              resetField={resetField}
            />
            <BehaviourPropertiesOGTemplate
              ruleId={ruleId}
              readOnlyMode={readOnlyMode}
              control={control}
              ogTemplates={operatorGuideTemplates}
              handleSelectOperatorGuide={handleSelectOperatorGuide}
              loading={loadingOperatorGuideTemplates}
              errors={errors}
            />
            <BehaviourPropertiesAutomatic
              ruleId={ruleId}
              readOnlyMode={readOnlyMode}
              control={control}
              handleSelectVideoSources={handleVideoSources}
            />
          </div>
        </div>
      ) : (
        <div>{translate("behaviors.noBehaviorSelected")}</div>
      )}
    </>
  );
};

export default BehaviourPropertyPanel;
