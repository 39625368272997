import { z, object, boolean } from "zod";
import RuleStringConstraint from "../RuleStringConstraint";
import RuleStringOptional from "../RuleStringOptional";
import { FormBehaviourIncidentTypeDto } from "./FormBehaviourIncidentTypeDto";
import RuleNumberConstraint from "../RuleNumberConstraint";

enum incidentPriorities {
  CRITICAL = "CRITICAL",
  MAJOR = "MAJOR",
  MINOR = "MINOR",
  WARNING = "WARNING",
  EMPTY = "",
}

enum aggregateMethods {
  SITE_AND_RULE = "SITE_AND_RULE",
  DISABLED = "DISABLED",
}

const IncidentPriorityEnum = z.nativeEnum(incidentPriorities);

export const AggregateMethodEnum = z.nativeEnum(aggregateMethods);

export const FormBehaviourDto = object({
  description: RuleStringConstraint,
  ogTemplateId: RuleStringOptional,
  ogTemplateName: RuleStringOptional,
  incidentTypeIconPath: RuleStringOptional,
  incidentTypeName: RuleStringOptional,
  incidentTypeId: RuleStringConstraint,
  incidentPriority: IncidentPriorityEnum,
  aggregationMethod: AggregateMethodEnum,
  automaticSnapshots: boolean(),
  videoSources: RuleNumberConstraint,
  // Form specific fields
  ogTemplate: object({
    id: RuleStringConstraint,
    name: RuleStringConstraint,
  }).nullable(),
  incidentType: FormBehaviourIncidentTypeDto,
  videoSourcesForm: object({
    id: RuleStringConstraint,
    name: RuleNumberConstraint,
  }).nullable(),
});

export type IncidentPriorityEnum = z.infer<typeof IncidentPriorityEnum>;
export type FormBehaviourDto = z.infer<typeof FormBehaviourDto>;
