import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { isUnity, useFeatureFlag, useI18n } from "compass-commons";
// Styles
import "./ConfigLayout.module.scss";
// Material UI
import { Button, NavbarWithRoutes } from "dms-lib";
// Custom Components
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import Sidebar from "../commons/sidebar/Sidebar";
import SiteTree from "../siteTree/SiteTree";
import OgConfigurator from "../ogConfigurator/OgConfigurator";
import FloorPlanConfigurator from "../floorPlanConfigurator/FloorPlanConfigurator";
import SubsystemConfigurator from "../subsystemConfigurator/SubsystemConfigurator";
import ResourceLocationConfigurator from "../resourceConfigurator/ResourceLocationConfigurator";
import GeneralConfigurator from "../generalConfiguration/GeneralConfigurator";
import ConfigContentHeader from "../configContentHeader/ConfigContentHeader";
import BehavioursConfigurator from "../incidentsConfiguration/BehavioursConfigurator";
import {
  TabActionsProvider,
  useTabActionsContext,
} from "../../contexts/TabActionsContext";
// Router
import { RoutesMap, MonitorRoutesMap } from "../../router/Routes";
import RouterGuard from "../../router/RouterGuard";
// Store
import { selectRoot, useStoreDispatch } from "../../store";
import { rootActions } from "../../store/root";
// Models
import { MenuTabRoute } from "../../models/configMenu/MenuTabRoute";
import EdgeDevicesPanel from "../edgeDevices/EdgeDevicesPanel";
import { SELF_INTEGRATIONS_PORTAL_FEATURE_FLAG } from "../../utils/Constants";
import { sitesActions } from "../../store/sites";

/**
 * Dynamic Router Guard Helper for Config Layout
 * Important to know the tab context
 * Ex. Tab content used to initialize the actions inside breadcrumb content menu
 */
const ConfigRouterWrapper = ({
  tabName,
  children,
}: PropsWithChildren<{ tabName: MenuTabRoute }>): JSX.Element => {
  const { selectedTab } = useSelector(selectRoot);

  const { extraResetHandler, setExtraResetHandler } = useTabActionsContext();

  const dispatch = useStoreDispatch();

  const onEnterHandler = useCallback(() => {
    if (tabName !== selectedTab) {
      extraResetHandler?.();
      // Clean reducer after being used
      setExtraResetHandler(() => {});
    }
    dispatch(rootActions.changeTab(tabName));
  }, [tabName]);

  return <RouterGuard onEnter={onEnterHandler}>{children}</RouterGuard>;
};

const getRoutes = () => (isDMS || isUnity ? RoutesMap : MonitorRoutesMap);

/**
 * Component responsable for the configuration page layout
 * First component that has the information about the site Id.
 * If no site id is passed then it will be initialized later with the site tree
 * @returns JSX.Element
 */
const ConfigLayout = (): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useStoreDispatch();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const {
    enabled: edgeDevicesFeatureFlagEnabled,
    isFeatureEnabled: isFeatureEnabledFunc,
  } = useFeatureFlag(appConfig, SELF_INTEGRATIONS_PORTAL_FEATURE_FLAG);
  const [routes, setRoutes] = useState(getRoutes());
  useMemo(() => {
    if (edgeDevicesFeatureFlagEnabled) {
      const newRoutes = [...getRoutes()];
      newRoutes.splice(1, 0, {
        path: MenuTabRoute.EdgeDevices,
        otherPaths: [`${MenuTabRoute.EdgeDevices}/*`],
        name: "navmenu.edgeDevices",
      });
      setRoutes(newRoutes);
    }
  }, [edgeDevicesFeatureFlagEnabled]);

  //
  // SIDEBAR REGION
  //

  const toggleSidebar = (forceState?: boolean) => {
    setIsSidebarHidden((prevState) => forceState ?? !prevState);
  };

  const openSidebar = () => toggleSidebar(false);
  const closeSidebar = () => toggleSidebar(true);

  //
  // END SIDEBAR REGION
  //

  // Checks if a siteId is passed as parameter, if not then is initialized with the root
  const { siteId } = useParams();

  useEffect(() => {
    dispatch(sitesActions.changeSelectedSiteId(siteId));
  }, [siteId]);

  return (
    <div className="config-layout">
      <TabActionsProvider>
        <section
          className={`config-layout__sidebar${
            isSidebarHidden ? " hidden" : ""
          }`}
        >
          <Sidebar sideBarAction={closeSidebar}>
            <SiteTree siteId={siteId} />
          </Sidebar>
        </section>
        <section className="config-layout__main">
          <NavbarWithRoutes
            className="config-layout__subnav"
            routesMap={routes.map((route) => ({
              ...route,
              name: t(route.name),
            }))}
            childrenBefore
          >
            {isSidebarHidden && (
              <Button
                id="sideBarCollapsible"
                name="sideBarCollapsible"
                color="primary"
                variant="contained"
                onClick={openSidebar}
                icon
              >
                <KeyboardDoubleArrowRightRoundedIcon />
              </Button>
            )}
          </NavbarWithRoutes>
          <div className="config-content">
            <ConfigContentHeader />
            <section className="config-content__panel">
              <Routes>
                <Route
                  index
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.Generic}>
                      <GeneralConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={MenuTabRoute.Subsystems}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.Subsystems}>
                      <SubsystemConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={MenuTabRoute.Resources}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.Resources}>
                      <ResourceLocationConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={`${MenuTabRoute.EdgeDevices}/*`}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.EdgeDevices}>
                      <EdgeDevicesPanel
                        isFeatureEnabledFunc={isFeatureEnabledFunc}
                      />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={MenuTabRoute.FloorPlans}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.FloorPlans}>
                      <FloorPlanConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={MenuTabRoute.OpGuides}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.OpGuides}>
                      <OgConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path={MenuTabRoute.Incidents}
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.Incidents}>
                      <BehavioursConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
                <Route
                  path="*"
                  element={
                    <ConfigRouterWrapper tabName={MenuTabRoute.Generic}>
                      <GeneralConfigurator />
                    </ConfigRouterWrapper>
                  }
                />
              </Routes>
            </section>
          </div>
        </section>
      </TabActionsProvider>
    </div>
  );
};

export default ConfigLayout;
