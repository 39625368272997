enum ConfigurationItemTypeDTO {
  STRING = "STRING",
  INTEGER = "INTEGER",
  BOOLEAN = "BOOLEAN",
  OBJECT = "OBJECT",
  ARRAY = "ARRAY",
  SENSITIVE = "SENSITIVE",
}

export default ConfigurationItemTypeDTO;
