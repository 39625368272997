// General
import { HttpClient } from "compass-shared-services";
// Models
import { ConfigurationDTO } from "../models/incidentEscalation/ConfigurationDTO";

const { INCIDENT_ESCALATION_SERVICE_PATH } = appConfig;
const URL_PATHS = {
  EXTERNAL_CONFIG: `${INCIDENT_ESCALATION_SERVICE_PATH}/configurations/external`,
};

const httpClient = new HttpClient(appConfig);

export default class IncidentEscalationService {
  static async upsertConfiguration(payload: ConfigurationDTO): Promise<void> {
    return httpClient
      .put<void>({
        url: URL_PATHS.EXTERNAL_CONFIG,
        payload,
      })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(error?.data?.errorMessage || error);
      });
  }

  static async getConfiguration(): Promise<ConfigurationDTO> {
    return httpClient
      .get<ConfigurationDTO>({
        url: URL_PATHS.EXTERNAL_CONFIG,
      })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(error?.data?.errorMessage || error);
      });
  }
}
