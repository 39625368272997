import { number } from "zod";

const RuleNumberConstraint = number()
  .min(0, {
    message: "behaviors.propertiesFieldsValidation.required",
  })
  .max(5, {
    message: "behaviors.propertiesFieldsValidation.maxSize",
  });

export default RuleNumberConstraint;
