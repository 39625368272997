import { z } from "zod";
import { FormBehaviourDto } from "./form/FormBehaviourDto";

export const BehaviourDto = FormBehaviourDto.omit({
  ogTemplate: true,
  incidentType: true,
  videoSourcesForm: true,
});

export type BehaviourDto = z.infer<typeof BehaviourDto>;
