// General
import React from "react";
import { useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
// Styles
import "../behaviourProperties.module.css";
// Components
import { AutoComplete, Checkbox, OptionType } from "dms-lib";
// Models
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
// Utils

interface BehaviourPropertiesAutomaticProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  handleSelectVideoSources?: (videoSources: number) => void;
}

interface VideoSourcesOption extends OptionType {
  name: number;
}

const BehaviourPropertiesIncidentType = (
  props: BehaviourPropertiesAutomaticProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const { ruleId, readOnlyMode, control, handleSelectVideoSources } = props;

  const videoSourceOptions = Array.from({ length: 6 }).map((_, index) => ({
    id: index.toString(),
    name: index,
  }));

  return (
    <div className="config-incidents__properties-input__automatically config-incidents__properties__input config-incidents__properties__dropdown">
      <span>
        {translate("behaviors.propertyFieldsDescription.automatically")}
      </span>
      <div className="config-incidents__properties__automatically">
        <div className="config-incidents__properties__automatically__automaticSnapshots">
          <Controller
            name="behaviour.automaticSnapshots"
            control={control}
            render={({ field: { onChange, value: automaticSnapshots } }) => {
              return (
                <div id={`automaticSnapshots-${ruleId}`}>
                  <Checkbox
                    checked={automaticSnapshots}
                    disabled={readOnlyMode}
                    FormControlClassName="config-incidents__properties-input__automaticSnapshots_checkbox"
                    onChange={(e, isChecked) => {
                      onChange(isChecked);
                    }}
                    dataCr="properties-automaticSnapshots"
                    label={translate(
                      "behaviors.propertyFieldsDescription.automaticSnapshots"
                    )}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="config-incidents__properties__automatically__videoSources">
          <div
            className="config-incidents__properties__input-controller config-incidents__properties__dropdown__videoSources"
            id="input-wrapper"
          >
            <Controller
              name="behaviour.videoSourcesForm"
              control={control}
              render={({ field: { onChange, ...field } }) => {
                return (
                  <AutoComplete
                    {...field}
                    {...{ size: "small" }}
                    value={field.value as VideoSourcesOption}
                    dataCr="properties-videoSources"
                    options={videoSourceOptions}
                    getOptionLabel={(option) => option.name.toString()}
                    id={`behaviour-property-incident-type-${ruleId}`}
                    disabled={readOnlyMode}
                    onChangeCallback={(data: VideoSourcesOption, _e) => {
                      onChange(data);
                      handleSelectVideoSources(data.name);
                    }}
                    disableClearable
                    disablePortal
                  />
                );
              }}
            />
          </div>
          <span
            className={`config-incidents__properties__label__videoSources ${
              readOnlyMode ? "disabled" : ""
            }`}
          >
            {translate("behaviors.propertyFieldsDescription.videoSources")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BehaviourPropertiesIncidentType);
